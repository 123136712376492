<template>
  <div>
    <!-- 号卡套餐 -->
    <area-tree @setQueryParams="handleQuery" :treeShow="url ? false : true">
      <!-- 搜索栏 -->
      <el-form class="params-wrap" size="small" :inline="true" :model="queryParams" >
        <el-form-item label="套餐状态:"  >
          <el-select v-model="queryParams.status" @change="refresh" clearable filterable placeholder="套餐状态" style="width:100px">
            <el-option label="全部" value="" ></el-option>
            <el-option label="上架中" :value="1"></el-option>
            <el-option label="已下架" :value="0" ></el-option>
          </el-select>
        </el-form-item> 
        <el-form-item>
          <el-select v-model="queryParams.operator" placeholder="请选择运营商" clearable filterable @change="refresh">
            <el-option label="中国移动" value="中国移动"></el-option>
            <el-option label="中国联通" value="中国联通"></el-option>
            <el-option label="中国电信" value="中国电信"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item >
            <el-input placeholder="请输入套餐名称" v-model="select2"  >
            <!-- <el-select v-model="select1"  slot="prepend" placeholder="请选择" style="width:100px">
              <el-option label="套餐名称" value="1"></el-option>
              <el-option label="学校ID" value="2"></el-option>
            </el-select> -->
          </el-input>
        </el-form-item>
        
        <el-form-item>
          <el-button type="success" icon="el-icon-search" @click="select" >搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" v-if="!this.isChecked" @click="openCheckedUp" >批量上架</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" v-if="!this.isChecked" @click="openCheckedDown">批量下架</el-button>
        </el-form-item>
        <el-form-item class="fr">
          <el-button type="success" size="small" icon="el-icon-plus"  @click="handleForm(false),fetchaBroadBandId()" >添加套餐</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" v-if="this.isChecked" @click="enterChecked" >完成</el-button>
        </el-form-item>
          <el-form-item>
          <el-button type="danger" v-if="this.isChecked" @click="closeChecked">取消</el-button>
        </el-form-item>
      </el-form>

      <div class="content-wrap">
      <!--列表-->
        <paged-table
          :data="cardList"
          :total="cardTotal"
          :loading="tLoading"
          :isShowSelection="false"
          defaultSortProp="id"
          v-model="queryParams"
          @selectionChange="handleSelectionChange"
          @refresh="refresh"
        >
          <el-table-column
            v-if = this.isChecked 
            type="selection"
            align="center"
            width="55">
          </el-table-column>
          <!-- <el-table-column prop="extend.schoolName" label="学校" :show-overflow-tooltip="true"></el-table-column> -->
          <!-- <el-table-column prop="operator" label="运营商" :show-overflow-tooltip="true"></el-table-column> -->
          <el-table-column width='150px' prop="name" label="套餐名称" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="operator" label="运营商" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column width='60px' prop="monthPrice" label="月租" :show-overflow-tooltip="true">
            <span slot-scope="scope">{{ (scope.row.monthPrice/100).toFixed(2) }}</span>
          </el-table-column>
          <el-table-column width='80px' prop="bandWidth" label="带宽（M）" v-if="url ? false : true" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column width='140px' prop="talk" label="通话时长（分钟）" :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column width='150px' prop="talkOutPrice" label="通话超出（元/分钟）" :show-overflow-tooltip="true">
            <span slot-scope="scope">{{ (scope.row.talkOutPrice/100).toFixed(2) }}</span>
          </el-table-column>
          <el-table-column
           width='110px' prop="flow" label="套餐流量（G）" :show-overflow-tooltip="true">
          </el-table-column><el-table-column
          width='130px' prop="flowOutPrice" label="流量超出（元/G）" :show-overflow-tooltip="true">
            <span slot-scope="scope">{{ (scope.row.flowOutPrice/100).toFixed(2) }}</span>
          </el-table-column>
          <!-- <el-table-column prop="iconUrl" label="套餐图片" height="100" align="center">
            <template slot-scope="scope">
              <i class="el-icon-picture" @click="downloadPath(scope.row.iconUrl)" style="font-size:30px; cursor:pointer" title="点击下载查看"></i>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="status"
            label="套餐状态"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">上架</span>
              <span v-if="scope.row.status == 0">下架</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="spreadStatus"
            label="分销状态"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.spreadStatus == 0">否</span>
              <span v-if="scope.row.spreadStatus == 1">是</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="detailImg" label="详情图片" height="100" align="center">
            <template slot-scope="scope">
              <i class="el-icon-picture" @click="downloadPath(scope.row.detailImg)" style="font-size:30px; cursor:pointer" title="点击下载查看"></i>
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop="content" label="套餐内容" :show-overflow-tooltip="true"></el-table-column> -->
          
          <!-- <el-table-column prop="price" label="原价(分)" :show-overflow-tooltip="true"></el-table-column> -->
          <el-table-column
            prop="realyPrice"
            label="售价(元)"
            :show-overflow-tooltip="true"
          >
            <span slot-scope="scope">{{ (scope.row.realyPrice/100).toFixed(2) }}</span>
          </el-table-column>
          <el-table-column
            prop="sort"
            label="套餐排序"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="groupFlag"
            label="是否团购"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.groupFlag == 1">是</span>
              <span v-if="scope.row.groupFlag == 0">否</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="groupNum"
            label="团购人数"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            width="140"
            align="center"
            prop="isActive"
            label="是否开启橱窗展示"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-switch 
                v-model="scope.row.isActive"
                :active-value=1
                @change="loadItem(scope.row)"
                :disabled="scope.row.isActive == 1?true:false"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            align="right"
            label="操作"
            width="120"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="handleForm(true, scope.row, afterHandleForm),fetchaBroadBandId()"
                title="编辑"
              ></el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="handleDelete({ id: scope.row.id })"
                :disabled="scope.row.status == 1"
                title="删除"
              ></el-button>
              <el-popover placement="top" trigger="click">
                <div style="text-align: right">
                  <el-button
                    size="mini"
                    icon="el-icon-search"
                    @click="handleDetails(scope.row)"
                    title="详情"
                  ></el-button>
                  <el-button
                    size="mini"
                    type="danger"
                    @click="handlePromotion(scope.row)"
                    icon="el-icon-s-promotion"
                    title="推广"
                  ></el-button>
                </div>
                <el-button size="mini" slot="reference" icon="el-icon-more" style="margin-left: 5px"></el-button>
              </el-popover>
            </template>
          </el-table-column>
        </paged-table>
      </div>
    </area-tree>

    <!-- 添加和编辑界面 -->
    <el-dialog :title="isEditor ? '编辑套餐' : '添加套餐'" :before-close="resetForm" :visible.sync="aVisible" width="1000px" :close-on-click-modal="false">
      <el-form label-width="180px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <!-- <el-form-item label="运营商：" prop="operator" style="width: 80%">
          <el-select v-model="form.operator" clearable filterable placeholder="请选择合运营商">
            <el-option label="中国移动" value="中国移动"></el-option>
            <el-option label="中国联通" value="中国联通"></el-option>
            <el-option label="中国电信" value="中国电信"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="套餐名称：" prop="name" style="width: 80%">
          <el-input v-model="form.name" clearable placeholder="请输入套餐名称"></el-input>
        </el-form-item>
        <el-form-item label="上传照片:" prop="iconUrl">
          <base-upload v-model="form.iconUrl" fileType="single" @beforeUpload="handleBeforeUpload"></base-upload>
          <div class="tips">图片尺寸800PX×800PX以上，文件大小在5MB以内,图片格式只支持：JPEG、JPG、PNG、GIF</div>
        </el-form-item>
        <!-- <el-form-item label="详情图片:" prop="detailImg">
          <base-upload v-model="form.detailImg" fileType="single" @beforeUpload="handleBeforeUpload"></base-upload>
          <div class="tips">图片尺寸800PX×800PX以上，文件大小在5MB以内,图片格式只支持：JPEG、JPG、PNG、GIF</div>
        </el-form-item> -->
        <el-row>
        <el-col :span="8">
          <el-form-item label="套餐标签" prop="labelId"  >
            <el-select v-model="form.labelId" @focus="fetchLabelAll" clearable filterable placeholder="请选择套餐标签">
              <el-option 
              v-for="(item,index) in labelAll"
              :label="item.name" 
              :value="item.id"
              :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
         </el-col>
        <el-col :span="4">
          <el-form-item label-width='20px' prop="iconUrl"  >
            <el-popover
              placement="bottom"
              title="自定义标签"
              width="400"
              trigger="manual"
              v-model="visible">
                <el-form label-width="110px" ref="formFileds" :model="selfForm"  size="small">
                  <el-form-item label="标签名称：" prop="name" >
                    <el-input v-model="selfForm.name" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="标签描述：" prop="description"   >
                    <el-input v-model="selfForm.description" type="textarea"  :rows="4"  clearable></el-input>
                  </el-form-item>
                </el-form>
                
                  <el-button size="small" @click="visible = false">取消</el-button>
                  <el-button size="small" type="primary" :loading="bLoading" @click="fetchSelfLabel">保存</el-button>
                
              <el-button slot="reference" @click="visible = !visible">自定义标签</el-button>
            </el-popover>
          </el-form-item>
        </el-col>
        </el-row>
        <el-form-item label="原价（元）" prop="price" style="width: 80%">
          <el-input v-model="form.price" oninput = "value=value.replace(/[^\d^\.]/g,'')"  clearable placeholder="请输入原价"></el-input>
        </el-form-item>
        <el-form-item label="售卖价（元）" prop="realyPrice" style="width: 80%">
          <el-input v-model="form.realyPrice" clearable oninput = "value=value.replace(/[^\d^\.]/g,'')" placeholder="请输入售卖价"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort" style="width: 80%">
          <el-input v-model="form.sort" clearable oninput = "value=value.replace(/[^\d^\.]/g,'')" placeholder="请输入序号"></el-input>
        </el-form-item>
        <el-form-item label="月租" prop="monthPrice" style="width: 80%">
          <el-input v-model="form.monthPrice" clearable oninput = "value=value.replace(/[^\d^\.]/g,'')" placeholder="请输入月租"></el-input>
        </el-form-item>
        <el-form-item label="带宽（M）" v-if="url ? false : true" prop="bandWidth" style="width: 80%">
          <el-input v-model="form.bandWidth" clearable oninput = "value=value.replace(/[^\d^\.]/g,'')" placeholder="请输入带宽"></el-input>
        </el-form-item>
        <el-form-item label="通话时长（分钟）" prop="talk" style="width: 80%">
          <el-input v-model="form.talk" clearable oninput = "value=value.replace(/[^\d^\.]/g,'')" placeholder="请输入通话时长（分钟）"></el-input>
        </el-form-item>
        <el-form-item label="通话超出计费（元/分钟）" prop="talkOutPrice" style="width: 80%">
          <el-input v-model="form.talkOutPrice" placeholder="请输入通话超出计费（元/分钟）" clearable oninput = "value=value.replace(/[^\d^\.]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="套餐流量" prop="flow" style="width: 80%">
          <el-input placeholder="请输入套餐流量" v-model="form.flow" clearable oninput = "value=value.replace(/[^\d^\.]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="流量超出时长（元/G）" prop="flowOutPrice" style="width: 80%">
          <el-input placeholder="流量超出时长（元/G）" v-model="form.flowOutPrice" clearable oninput = "value=value.replace(/[^\d^\.]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="套餐状态" prop="status" style="width: 80%" >
          <el-radio-group v-model="form.status">
            <el-radio :label="1">上架</el-radio>
            <el-radio :label="0">下架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="宽带名称" prop="mealId" style="width: 80%" v-if="url ? false : true">
          <el-select  v-model="form.mealId" clearable filterable placeholder="请选择宽带">
            <el-option 
            v-for="(item,index) in broadBandId"
            :label="item.name" 
            :value="item.id"
            :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否团购" prop="groupFlag" style="width: 80%" >
          <el-radio-group v-model="form.groupFlag">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="团购人数" prop="groupNum" style="width: 80%" v-if="form.groupFlag==1">
          <el-input v-model="form.groupNum" placeholder="请输入团购人数" clearable ></el-input>
        </el-form-item>
        <!-- <el-form-item label="加入首页橱窗" prop="isActive" style="width: 80%" >
          <el-radio-group v-model="form.isActive">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="套餐内容" prop="content" style="width: 80%">
          <tinymce v-model="form.content" v-if="aVisible"></tinymce>
        </el-form-item>
        <!-- <el-form-item  label="选择学校" prop="schoolId" style="width: 80%">
          <el-select  v-model="form.schoolId" @change="fetchSchoolDicts" clearable filterable placeholder="请选择学校">
              <el-option 
              v-for="(item,index) in schoolAll"
              :label="item.schoolName" 
              :value="item.id"
              :key="index"
              ></el-option>
            </el-select>
        </el-form-item> -->
        <!-- <el-form-item  label="选择校区" prop="distId" style="width: 80%">
          <el-select   v-model="form.distId" @focus="fetchSchoolDicts" clearable filterable placeholder="请选择校区">
              <el-option 
              v-for="(item,index) in schoolDicts"
              :label="item.distName" 
              :value="item.id"
              :key="index"
              ></el-option>
            </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="生效规则" prop="activityRule" >
          <tinymce v-if="aVisible" v-model="form.activityRule"></tinymce>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetForm">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi,formChange,resetForm) : submitForm(insertApi,formChange,resetForm)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog title="套餐详情" :visible.sync="bVisible" width="800px" :close-on-click-modal="false">
      <el-form label-width="180px" ref="detailsFileds" :model="details"  size="small">
        <el-form-item label="运营商：" prop="operator" style="width: 90%">
            <span v-text="details.operator" clearable></span>
        </el-form-item>
        <el-form-item label="套餐名称：" prop="name" style="width: 90%">
          <span v-text="details.name" clearable></span>
        </el-form-item>
        <el-form-item label="图标:" prop="iconUrl">
          <el-image :src="details.iconUrl" style="width:50%"></el-image>
        </el-form-item>
        <!-- <el-form-item label="详情图片:" prop="detailImg">
          <el-image :src="details.detailImg" style="width:80%"></el-image>
        </el-form-item> -->
        <el-form-item label="原价(元):" prop="price" style="width: 90%">
          <span v-text="(details.price/100).toFixed(2)" clearable></span>
        </el-form-item>
        <el-form-item label="售卖价(元):" prop="realyPrice" style="width: 90%">
          <span v-text="(details.realyPrice/100).toFixed(2)" clearable></span>
        </el-form-item>
        <el-form-item label="排序:" prop="sort" style="width: 90%">
          <span v-text="details.sort" clearable></span>
        </el-form-item>
        <el-form-item label="套餐状态:" prop="status" style="width: 90%">
          <span v-if="details.status == 1" clearable>上架</span>
          <span v-if="details.status == 0" clearable>下架</span>
        </el-form-item>
        <el-form-item label="是否团购:" prop="groupFlag" style="width: 90%">
          <span v-if="details.groupFlag == 1" clearable>是</span>
          <span v-if="details.groupFlag == 0" clearable>否</span>
        </el-form-item>
        <el-form-item label="团购人数:" prop="groupNum" style="width: 90%">
          <span v-text="details.groupNum" clearable></span>
        </el-form-item>
        <el-form-item label="活动套餐:" prop="isActive" style="width: 90%">
          <span v-if="details.isActive == 1" clearable>活动套餐</span>
          <span v-if="details.isActive == 0" clearable>非活动套餐</span>
        </el-form-item>
        <el-form-item label="月租:" prop="monthPrice" style="width: 90%">
          <span v-text="(details.monthPrice/100).toFixed(2)" clearable></span>
        </el-form-item>
        <el-form-item label="带宽:" prop="bandwidth" style="width: 90%">
          <span v-text="details.bandwidth" clearable></span>
        </el-form-item>
        <el-form-item label="通话时长:" prop="talk" style="width: 90%">
          <span v-text="details.talk" clearable></span>
        </el-form-item>
        <el-form-item label="通话超出计费（元/分钟）:" prop="talkOutPrice" style="width: 90%">
          <span v-text="(details.talkOutPrice/100).toFixed(2)" clearable></span>
        </el-form-item>
        <el-form-item label="套餐流量（G）:" prop="flow" style="width: 90%">
          <span v-text="details.flow" clearable></span>
        </el-form-item>
        <el-form-item label="流量超出计费（元/G）:" prop="flowOutPrice" style="width: 90%">
          <span v-text="(details.flowOutPrice/100).toFixed(2)" clearable></span>
        </el-form-item>
        
        <el-form-item label="套餐内容:" prop="content" style="width: 90%">
          <span v-html="details.content" clearable></span>
        </el-form-item>
        <el-form-item label="学校名称:" prop="extend.schoolName" style="width: 90%">
          <span v-text="details.extend.schoolName" clearable></span>
        </el-form-item>
        <el-form-item label="生效规则:" prop="activityRule" style="width: 90%">
          <div v-html="details.activityRule" ></div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="bVisible = false">取消</el-button>
        <!-- <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button> -->
      </div>
    </el-dialog>

    <!-- 推广 -->
    <el-dialog title="推广套餐" :visible.sync="promotionVisible" width="900px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="promotionForm" :model="promotionForm" :rules="promotionRules" size="small">
        <el-form-item label="分销文案：" prop="note" style="width: 100%">
          <tinymce v-model="promotionForm.note"></tinymce>
        </el-form-item>
        <el-form-item label="商品图片：" prop="ids">
          <base-upload v-model="promotionForm.ids"  fileType="multiple"  :limit="3" :uploadData="uploadData" @beforeUpload="handleBeforeUpload"></base-upload>
          <div class="tips">图片尺寸800PX×800PX以上，文件大小在5MB以内,图片格式只支持：JPEG、JPG、PNG、GIF</div>
        </el-form-item>
        <el-form-item label="分销需求量：" prop="demand"> 
          <el-input v-model.number="promotionForm.demand" placeholder="请输入分销需求量" clearable></el-input>
        </el-form-item>
        <el-form-item label="分销总金额：" prop="goodsCommission">
          <el-input v-model.number="promotionForm.goodsCommission" placeholder="请输入佣金" clearable>
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        
        <el-form-item>
          <div class="edit">
            <el-button class="btn" @click="promotionVisible = false">取消</el-button>
           <el-button class="btn submit" v-if="promotionForm.status == null" @click="addPromotion('promotionForm')">暂存</el-button>
            <el-button class="btn submit" v-if="promotionForm.status == 1" @click="updatePromotion('promotionForm')">提交</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 橱窗推广 -->
    <el-dialog
    width="30%"
    title="橱窗推广"
    :visible.sync="innerVisible"
    @close="innerVisible = false,refresh()"
    append-to-body>
      <div class="pay">
          <p>请选择支付方式:</p>
          <div class="cont">
              <div class="payType" :class="payIndex == index?'active':''" v-for="(item,index) in payType" :key="index" @click="payIndex = index">
                  <img :src="item.imageUrl" alt="">
              </div>
          </div>
          <div class="payNext">
              <!-- <p>点击下一步同意 <span>《校园CEO大赛充值服务协议》</span></p> -->
              <div class="edit">
                  <el-button class="btn" @click="innerVisible = false,refresh()">取消</el-button>
                  <el-button class="btn submit" @click="payment">下一步</el-button>
              </div>
          </div>
      </div>
      <!-- 微信二维码 -->
      <el-dialog title="微信支付" :visible.sync="jVisible" center width="400px" append-to-body>
          <img :src="wxpayUrl" alt="" style="margin:0px auto;display:block;width:300px;height:300px">
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseImport from "@/components/common/BaseImport";
import BaseUpload from "@/components/common/BaseUpload";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import download from "@/mixins/download";
import { BaseUrl } from "@/common/config";
import Tinymce from "@/tinymce";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'card',
  components: {
    AreaTree,
    PagedTable,
    Tinymce,
    BaseUpload
  },
  mixins: [pageMixin,provinces,download],
  data() {
    let checkNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('佣金不能为空'));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
        } else {
          callback();
        }
      }, 500);
    };
    return {
      wxpayUrl:'',
      jVisible:false,
      timer:null,
      activeMealId:'',
      activeShopId:'',
      innerVisible: false,
      url:'',
      BaseUrl,
      queryParams: {
        status:'',
        schoolId:'',
        name:'',
      },
      // 批量上架/下架
      BatchStatusChange:{
        ids:'',
        status:''
      },
      labelAll:[],
      //自定义标签
      selfForm:{
        name:'',
        description:'',
        id:''
      },
      promotionForm: {
        goodsId:null,
        note:'',
        goodsCommission: null,
        demand:null,
        ids:[],
      },
      editSave: {},
      promotionVisible: false,
      isChecked:false,
      // 下拉框筛选
      select1:'1',
      select2:'',

      schoolType: [],
      form:{
        id: null,
        detailImg:'',
        monthPrice:'',
        bandWidth:'',
        talk:'',
        talkOutPrice:'',
        flow:'',
        labelId:'',
        flowOutPrice:'',
        // schoolId:'',
        // distId:'',
        name:'',
        iconUrl:'',
        // operator:'',
        price:'',
        sort:'',
        realyPrice:'',
        content:'',
        status: 1,
        // activityRule:'',
        groupFlag: 1,
        isActive: 1,
        groupNum:'',
        mealId:'',
      },
      details:{
        schoolName:'',
        department:'',
        extend:{},
        remark: ''
      },
      visible: false,
      schoolAll:[],
      schoolDicts:[],
      broadBandId:[],
      formRules: {
        name: [{ required: true, message: "请输入资料名称", trigger: "blur" }],
        iconUrl: [{ required: true, message: "请上传图片", trigger: "blur" }],
        operator: [{ required: true, message: "请选择运营商", trigger: "blur" }],
        schoolId: [{ required: true, message: "请选择学校", trigger: "blur" }],
        price: [{ required: true, message: "请输入原价", trigger: "blur" }],
        realyPrice: [{ required: true, message: "请输入售卖价", trigger: "blur" }],
        monthPrice: [{ required: true, message: "请输入月租", trigger: "blur" }],
        bandWidth: [{ required: true, message: "请输入带宽", trigger: "blur" }],
        status: [{ required: true, message: "请选择套餐状态", trigger: "blur" }],
        mealId: [{ required: true, message: "请选择宽带名称", trigger: "blur" }],
        groupFlag: [{ required: true, message: "请选择是否团购", trigger: "blur" }],
        groupNum: [{ required: true, message: "请选择团购人数", trigger: "blur" }],
        isActive: [{ required: true, message: "请选择是否加入首页橱窗", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
      // 分销
      promotionRules: {
        ids:[{ required: true, message: '请上传商品图片', trigger: 'change' }],
        demand:[{ required: true, message: '请输入分销需求量', trigger: 'blur' },
        {type:'number',message:'需求量必须为数值', trigger: 'blur'}],
        note:[{ required: true, message: '请输入分销文案', trigger: 'blur' },],
        goodsCommission:[{ required: true, message: '请输入佣金', trigger: 'blur' },
        { validator:checkNum,trigger: 'blur'  }]
      },
      // 上传图片格式
      uploadData:{
				bizType:'',
				bizId:'',
				needStore:'1',
			},
      // 支付类型
      payType:[
          {
              type:1,
              imageUrl: require('@/assets/images/alpay.jpg')
          },
          {
              type:2,
              imageUrl: require('@/assets/images/wechat.jpg')
          }
      ],
      payIndex:null,


      pageApi:'getCardList',
      insertApi:'getCardAdd',
      editorApi:'getCardEdit',
      deleteApi:'getCardDelete',
      loadApi:'getCardLoad',
    };
  },
  filters:{
    money(val) {
      return (val/100).toFixed(2)
    }
  },
  computed: {
    ...mapState("card", ["cardList", "cardTotal"]),
    totalMoney() {
      let money
      if(this.promotionForm.commission1 != null && this.promotionForm.commission2 != null && this.promotionForm.commission3 != null){
        money = parseFloat(this.promotionForm.commission1) + parseFloat(this.promotionForm.commission2) + parseFloat(this.promotionForm.commission3)
      }else{
        money = 0
      }
      return money.toFixed(2)
    }
  },
  methods: {
    ...mapActions("card",[
      "getCardList",
      "getCardAdd",
      "getCardEdit",
      "getCardLoad",
      "getCardDelete",
      "getSchoolAll",
      "getSchoolDicts",
      "getBatchStatusChange",
      "goodsSelectAll",
      "addShopPromotion",
      'stopPromote',
      'promoteInfo',
      'storePromote',
      'updatePromote'
      ]),
      ...mapActions("goodsLable",[
       "getGoodsLableList",
       "getGoodsLableAdd"
       ]),
      ...mapActions("broadBand",[
      "getBroadBand",
      'showcasePay',
      'paySuccess'
      ]),
    formChange(params){
      // log('params',params)
      // if(this.editSave){
        params.price = params.price*100
        params.realyPrice = params.realyPrice*100
        params.monthPrice = params.monthPrice*100
        params.talkOutPrice = params.talkOutPrice*100
        params.flowOutPrice = params.flowOutPrice*100
      // }else{
      //   params.price = this.editSave.price*100
      //   params.realyPrice = this.editSave.realyPrice*100
      //   params.monthPrice = this.editSave.monthPrice*100
      //   params.talkOutPrice = this.editSave.talkOutPrice*100
      //   params.flowOutPrice = this.editSave.flowOutPrice*100
      // }
      return true
    },
    async afterHandleForm(row){
      this.editSave = row
      this.form.price = row.price/100
      this.form.realyPrice = row.realyPrice/100
      this.form.monthPrice = row.monthPrice/100
      this.form.talkOutPrice = row.talkOutPrice/100
      this.form.flowOutPrice = row.flowOutPrice/100
    },
  // 点击搜索
    // money() {
    //   this.promotionForm.totalMoney=this.promotionForm.singleNumber*this.promotionForm.orderNumber
    // },
    // 获取标签
     async fetchLabelAll() {
       let obj = {
         pageNum:1,
         pageSize:10,
       }
        const res = await this.getGoodsLableList(obj)
        this.labelAll = res.list
        console.log(res,"标签");
    },
    // 自定义标签
      async fetchSelfLabel() {
        this.visible = false
        const res = await this.getGoodsLableAdd(this.selfForm)
        this.fetchLabelAll()
        this.selfForm = ''
    },
    select(){
      if(this.select1 == 1){
        this.queryParams.schoolId = ''
        this.queryParams.name = this.select2
        console.log(1);
      }
      if(this.select1 == 2){
        this.queryParams.name = ''
        this.queryParams.schoolId = this.select2
        console.log(2);
      }
      this.refresh()
    },
    async handlePromotion(row) {
      console.log(row.id)
      this.promotionForm.goodsId = row.id
      this.promotionVisible = true
    },
    // 获取宽带Id
    async fetchaBroadBandId() {
        const res = await this.goodsSelectAll({status:1,mergeFlag:1})
        this.broadBandId = res
    },
    // 获取学校
     async fetchSchoolAll() {
        const res = await this.getSchoolAll({})
        this.schoolAll = res
    },
    // 获取校区
     async fetchSchoolDicts() {
       this.form.distId = ''
        const res = await this.getSchoolDicts({schoolId:this.form.schoolId})
        this.schoolDicts = res
    },
    // 打开checked
    openCheckedUp(){  //上架
      this.isChecked = true
      this.BatchStatusChange.status = 1
    },
    openCheckedDown(){  //下架
      this.isChecked = true
      this.BatchStatusChange.status = 0
    },
    // 关闭checked
    closeChecked(){
      this.isChecked = false
       this.BatchStatusChange.status = ''
       this.BatchStatusChange.ids = ''
    },
    // 确定checked
    async enterChecked(){
      const res = await this.getBatchStatusChange(this.BatchStatusChange)
      this.refresh()
      this.isChecked = false
    },
    // 选择的ids
    handleSelectionChange(val) {
      let arr = []
      let idArr = []
      let ids = ''
      arr = val.map((item,index) => {
        return Object.assign({},{'ids':item.id})
      })
      for(var i in arr){
        for(var j in arr[i]){
          idArr.push(arr[i][j])
        }
      }
      ids = idArr.toString()
      this.BatchStatusChange.ids = ids
    },
    // 图片上传
     handleBeforeUpload(file, callback){
		  const isPicture = file.name.includes('jpg') || file.name.includes('png') || file.name.includes('gif');
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isPicture) {
        this.$message.error('上传图片只能是 JPG/PNG/gif 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      callback(isPicture && isLt2M)
    },
    handleQuery(data){
      // log(data)
      if ((data.id + "").length === 9) {
        this.queryParams.province = data.id;
      }
      if ((data.id + "").length === 12) {
        this.queryParams.city = data.id;
      }
      if ((data.id + "").length === 15) {
        this.queryParams.area = data.id;
      }
      if ((data.id + "").length <9) {
          this.queryParams.schoolId = data.id;
      }
      this.refresh()
    },
    resetForm(){
      this.form.id = null
      this.aVisible = false
    },
    // 添加分销
    async handlePromotion(row) {
      let res = await this.promoteInfo({goodsId:row.id})
      this.promotionForm = {
        goodsId: row.id,
        ids:res.picList,
        note:res.note,
        goodsCommission: parseFloat(res.goodsCommission/100),
        demand:res.demand,
        status:res.status
      }
      console.log(this.promotionForm)
      this.promotionVisible = true
    },
    // 添加分销
    addPromotion(formName) {
      this.$refs[formName].validate(async(valid) => {
          if (valid) {
            let {status,...params} = this.promotionForm
            params.goodsCommission = this.promotionForm.goodsCommission*100
            params.ids = this.promotionForm.ids.map(item => item.id)
            console.log(params)
            let res = await this.storePromote(params)
            if (res != null) {
              this.promotionVisible = false
              this.$message.success('暂存分销成功')
              this.refresh()
            }
          } else {
            return false;
          }
        });
    },
    // 上架分销
    updatePromotion(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          let {status,...params} = this.promotionForm
          params.goodsCommission = this.promotionForm.goodsCommission*100
          params.ids = this.promotionForm.ids.map(item => item.id)
          console.log(params)
          let res = await this.updatePromote(params)
          if (res == null) {
            this.promotionVisible = false
            this.$message.success('提交分销成功')
            this.refresh()
          }
        } else {
          return false;
        }
      });
    },
    // 停止分销
    async stopPromotion(id) {
      let res =  await this.stopPromote({goodsId:id})
      if (res != null) {
        this.$message.success('停止推广成功')
        this.refresh()
      }
    },
    //  橱窗推广  支付
    // 
    loadItem(item){
      if (item.isActive == 1) {
        this.innerVisible = true
        //
        this.activeMealId = item.id
        this.activeShopId = item.shopId
      }else{
        this.innerVisible = false
      }
    },
    // 充值
    async payment() { 
        let params = {
            payType:this.payType[this.payIndex].type,
            mealId: this.activeMealId,
            shopId: this.activeShopId,
            mealType:2,
            hallType: this.url?1:2
        }
        if (this.payType[this.payIndex].type == 1) {
            // 支付宝 1
           let routeUrl = this.$router.resolve({
             path:'/aliPay',
             query:params
           })
            window.open(routeUrl.href,'_blank')
            this.payStatus()
        }else{
              // 微信 2
            //this.innerVisible = false
            this.jVisible = true
            let res = await this.showcasePay(params)
            localStorage.setItem('orderId',res.orderNum)
            // 转二维码
            QRCode.toDataURL(res.form).then((url)=>{
                this.wxpayUrl = url
            }).catch(err => {
                console.error(err)
            })
            this.payStatus()
        }
    },
    // 查询支付状态
    async payStatus(){
        let res = await this.paySuccess({outTradeNo:localStorage.getItem('orderId'), type:1})
        if(res == true){
            this.$message.success('充值成功')
            clearInterval(this.timer)
            this.innerVisible = false
            this.jVisible = false
            this.timer = null
            this.refresh()
        }else{
          if (this.innerVisible == true || this.jVisible == true) {
            setTimeout(this.payStatus, 3000)
          }else{
            this.refresh()
          }
        }
    },
  },
  mounted(){
    this.url = window.location.href.includes('sim.') || window.location.href.includes('20033')
    console.log(this.url)
    this.refresh()
    this.fetchSchoolAll()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.item-line {
  display: inline-block;
  .w100 {
    width: 170px;
  }
}
.edit {
  .btn {
    margin-right: 20px;
    width: 140px;
    &.submit {
      color: $c-white;
      background-color: $c-success;
    }
  }
}
.tinymce-textarea {
  padding: 10px;
  width: 100%;
  border: 1px solid #999;
}
.row { 
  /deep/.el-input__inner{ height: 30px; }
  font-size: 16px;
 }
.pay {
    >p {
        font-size: 16px;
        color: #222;
    }
    .cont {
        display: flex;
        justify-content: space-around;
        .payType {
            margin: 10px;
            width: 200px;
            height: 70px;
            border: 1px solid #EAEFF3;
            border-radius: 6px;
            img {
                margin: 7px auto;
                width: 150px;
                height: 55px;
                display: block;
            }
            &.active {
                position: relative;
                border: 1px solid #28B892;
            }
        }
        .active::after {
            content: '';
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            bottom: 0;
            right: 0;
            background: url('../.././assets/images/money_active.png');
        }
    }
    .payNext {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        >p {
            line-height: 36px;
            margin-left: 100px;
            font-size: 14px;
            color: #AFAFAF;
            >span {
                color: #4A90E2;
            }
        }
        &>p::before {
            content: '*';
            margin-right: 5px;
            color: #FF5353;
        }
        .edit {
          width: 100%;
          display: flex;
          justify-content: center;
            .btn {
                width: 140px;
                &.submit {
                    color: #fff;
                    background-color: #28B892;
                }
            }
        }
    }
}
</style>
